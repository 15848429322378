
import Vue from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default Vue.extend({
  name: "RecipientsSelector",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selectedCamera: {
      type: Object,
      default: () => ({}),
    },
    cameraExids: {
      type: Array,
      default: () => [],
    },
    disableSuggestions: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isLoading: false,
      search: null,
      usersList: [],
      delimiters: [",", " "],
      recipients: [],
      // eslint-disable-next-line no-useless-escape
      reg: /^(?!.*\.{2})[a-zA-Z0-9!.#$%&'*+"\/=?^_`{|}~-]+@[a-zA-Z\d\-]+(\.[a-zA-Z\d\-]+)*\.[a-zA-Z0-9]+z*$/,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    autoSelectMenu() {
      return this.search?.length > 1
    },
  },
  watch: {
    selectedCamera: {
      handler(camera) {
        this.shareUserEmailOptions(camera?.id)
      },
      immediate: true,
      deep: true,
    },
    cameraExids: {
      handler(cameraExids) {
        this.projectShareUserEmailOptions(cameraExids)
      },
      immediate: true,
      deep: true,
    },
    recipients: {
      handler(recipients) {
        this.$emit("input", recipients)
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.recipients = this.value
  },
  methods: {
    checkColor(item) {
      return this.reg.test(item.email) ? "" : "error_container"
    },
    handlePaste(event) {
      if (event.clipboardData) {
        const pastedText = event.clipboardData
          .getData("Text")
          .replace(/[<>]/g, "")
        event.preventDefault()
        let items = pastedText.split(/[:,|\s]/).reduce((acc, item) => {
          if (item != "" && this.reg.test(item)) {
            return [...acc, { email: item.trim(), name: null }]
          } else {
            return acc
          }
        }, "")
        this.recipients = [...this.recipients, ...items]
      }
    },
    handleUserChange(items) {
      this.recipients = items.map((item) => {
        if (typeof item === "string") {
          return {
            name: null,
            email: item,
          }
        } else {
          return item
        }
      })
    },
    async shareUserEmailOptions(cameraExid) {
      if (
        this.disableSuggestions ||
        !cameraExid ||
        this.isLoading ||
        (!this.search && this.usersList.length > 0)
      ) {
        return
      }

      try {
        this.isLoading = true
        const response = await EvercamApi.shares.getSharedUsers(cameraExid)
        this.usersList = response?.map((user) => user.email)
        this.usersList = [...this.usersList, this.accountStore.email]
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async projectShareUserEmailOptions(cameraExids) {
      if (
        this.disableSuggestions ||
        !cameraExids.length ||
        this.isLoading ||
        (!this.search && this.usersList.length > 0)
      ) {
        return
      }

      try {
        this.isLoading = true
        const responses = await Promise.all(
          cameraExids.map((exid) => EvercamApi.shares.getSharedUsers(exid))
        )

        // Flatten the array of arrays and map to get the emails
        let usersList = responses.flat().map((user) => user.email)

        // Add the current user's email
        usersList = [...usersList, this.accountStore.email]

        // Convert to a Set and back to an array to remove duplicates
        this.usersList = [...new Set(usersList)]
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    recipientLabel(item) {
      return item.name ?? item.email
    },
  },
})
